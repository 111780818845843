import {Alert, AlertDescription, AlertIcon} from "@chakra-ui/react";
import React from "react";

export function BoletaWarning() {
  return <Alert
    status='warning'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    textAlign='center'
    py={5}
  >
    <AlertIcon boxSize='40px' mr={0} mb={2}/>
    <AlertDescription maxWidth='sm'>
      De no existir reclamos sobre el pago en los próximos 8 días se dará por bien hecha la transacción y se da
      por aceptada la boleta de pago
    </AlertDescription>
  </Alert>
}