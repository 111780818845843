import {useRouteError} from "react-router-dom";
import {Alert, AlertDescription, AlertIcon, AlertTitle, Box} from "@chakra-ui/react";
import React from "react";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Box mt={10}>
      <Alert
        borderRadius={10}
        status='error'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0}/>
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          Ooops!
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          Ha ocurrido un error inesperado
        </AlertDescription>
      </Alert>
    </Box>
  );
}