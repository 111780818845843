import React from 'react';
import {ChakraProvider, Flex} from "@chakra-ui/react";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import ErrorPage from './pages/ErrorPage';
import {AceptarBoletaPage} from "./pages/AceptarBoletaPage";
import SuccessPage from "./pages/SuccessPage";
import NotFoundPage from "./pages/NotFoundPage";

const router = createBrowserRouter([
  {
    path: "/aceptar-boleta",
    element: <AceptarBoletaPage/>,
    errorElement: <NotFoundPage/>,
  },
  {
    path: "/success",
    element: <SuccessPage/>,
    errorElement: <NotFoundPage/>,
  },
  {
    path: "/",
    element: <ErrorPage/>,
    errorElement: <ErrorPage/>,
  },
]);

function App() {

  return (
    <ChakraProvider>
      <Flex w="100vw" h="100vh" bg="#191e30" justifyContent="center" alignItems="start" overflow="auto">
        <RouterProvider router={router}/>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
