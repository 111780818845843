import {Table, Tbody, Td, Tr} from "@chakra-ui/react";
import React from "react";
import {IEmployeeInfo} from "../api/boletas-api";

interface IEmployeeInfoCardProps {
  employee: IEmployeeInfo
}

export function EmployeeInfoCard(props: IEmployeeInfoCardProps) {
  const {employee} = props;
  return (
    <Table variant="striped" size="sm">
      <Tbody>
        <Tr>
          <Td fontWeight="semibold">Nombre</Td>
          <Td>{employee.name}</Td>
        </Tr>
        <Tr>
          <Td fontWeight="semibold">Empresa</Td>
          <Td>{employee.empresa}</Td>
        </Tr>
        <Tr>
          <Td fontWeight="semibold">Periodo</Td>
          <Td>{employee.fromDate} - {employee.toDate}</Td>
        </Tr>
        <Tr>
          <Td fontWeight="semibold">Banco</Td>
          <Td>{employee.bank}</Td>
        </Tr>
        <Tr>
          <Td fontWeight="semibold">Modo de pago</Td>
          <Td>{employee.paymentMethod}</Td>
        </Tr>
        <Tr>
          <Td fontWeight="semibold">Tipo de Cuenta</Td>
          <Td>{employee.accountType}</Td>
        </Tr>
        <Tr>
          <Td fontWeight="semibold">Número de Cuenta</Td>
          <Td>{employee.accountNumber}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}