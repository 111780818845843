import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Spinner,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useToast,
  VStack
} from "@chakra-ui/react";
import {EmployeeInfoCard} from "../components/EmployeeInfoCard";
import React, {useCallback, useEffect, useState} from "react";
import {aceptarBoleta, Boleta, getBoletas, rechazarBoleta} from "../api/boletas-api";
import {DetailsTable} from "../components/DetailsTable";
import {BoletaWarning} from "../components/BoletaWarning";
import {CheckIcon, CloseIcon} from "@chakra-ui/icons";
import {useNavigate, useSearchParams} from "react-router-dom";


export function AceptarBoletaPage() {
  const [boleta, setBoleta] = useState<Boleta>();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const idBoleta = searchParams.get('nB') || null;
  const [isDone, setIsDone] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);

  useEffect(() => {
    if (!idBoleta) {
      navigate(`/404`);
      return;
    }
    getBoletas(idBoleta)
      .then(setBoleta)
      .catch(() => {
        navigate(`/404`);
      });
  }, [idBoleta, navigate, toast]);

  const onAcceptClick = useCallback(() => {
    if (!idBoleta) {
      return;
    }
    setIsAcceptLoading(true);
    aceptarBoleta(idBoleta)
      .then(() => toast({
        title: 'Confirmación enviada!',
        description: "Revisar su teléfono por la confirmación del sistema.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      }))
      .catch(() => toast({
        title: 'Error.',
        description: "Ha ocurrido un error inesperado",
        status: 'error',
        duration: 5000,
        isClosable: true,
      }))
      .finally(() => {
        setIsDone(true);
        setIsAcceptLoading(false);
      });
  }, [idBoleta, navigate, toast]);

  const onRejectClick = useCallback(() => {
    if (!idBoleta) {
      return;
    }
    setIsRejectLoading(true);
    rechazarBoleta(idBoleta)
      .then(() => toast({
        title: 'Éxito',
        description: "La boleta de pago ha sido rechazada!",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      }))
      .catch(() => toast({
        title: 'Error.',
        description: "Ha ocurrido un error inesperado",
        status: 'error',
        duration: 5000,
        isClosable: true,
      }))
      .finally(() => {
        setIsDone(true);
        setIsRejectLoading(false);
      });
    ;
  }, [idBoleta, navigate]);

  if (!boleta) {
    return <Spinner color='red.500' mt={10} size='xl'/>;
  }

  return <Card my={10} mx={3}>
    <CardHeader borderBottomStyle="solid" borderBottomWidth={1}>
      <Heading size='sm'>Centrarecursos S.A.</Heading>
      <Heading size='xs' color="gray.500">Nit: 6057911-0</Heading>
    </CardHeader>
    <CardBody px={0}>
      <VStack spacing={5} align="stretch">
        <Box>
          <Heading size="md" mx={5} mb={5}>Datos del colaborador</Heading>
          <EmployeeInfoCard employee={boleta.employee}/>
        </Box>
        <Divider/>
        <Box>
          <Heading size="md" mx={5} mb={5}>Ingresos</Heading>
          <DetailsTable details={boleta.ingresos} total={boleta.totalIngresos}/>
        </Box>
        <Box>
          <Heading size="md" mx={5} mb={5}>Descuentos</Heading>
          <DetailsTable details={boleta.descuentos} total={boleta.totalDescuentos}/>
        </Box>
        <Box textAlign="center">
          <Stat>
            <StatLabel>Total</StatLabel>
            <StatNumber>Q.{boleta.total.toLocaleString()}</StatNumber>
            <StatHelpText>Periodo: {boleta.employee.fromDate} - {boleta.employee.toDate}</StatHelpText>
          </Stat>
        </Box>
        <BoletaWarning/>
        <HStack justifyContent="space-around">
          <Button leftIcon={<CloseIcon/>} colorScheme="red" size='lg' isLoading={isRejectLoading} isDisabled={isDone}
                  onClick={onRejectClick}>
            Rechazar
          </Button>
          <Button leftIcon={<CheckIcon/>} colorScheme="green" size='lg' isLoading={isAcceptLoading} isDisabled={isDone}
                  onClick={onAcceptClick}>
            Aceptar
          </Button>
        </HStack>
      </VStack>
    </CardBody>
  </Card>
}