import {IDetailItem} from "../api/boletas-api";
import {Table, Tbody, Td, Tfoot, Th, Thead, Tr} from "@chakra-ui/react";
import React from "react";

export interface IDetailsTableProps {
  details: IDetailItem[];
  total: number;
}

export function DetailsTable(props: IDetailsTableProps) {
  const {details, total} = props;

  const rows = details.map((detail, index) => (
    <Tr key={index}>
      <Td>{detail.description}</Td>
      <Td isNumeric>Q.{detail.value?.toLocaleString()}</Td>
    </Tr>
  ));

  return (
    <Table variant="striped" size="sm">
      <Thead>
        <Tr>
          <Th>Descripción</Th>
          <Th>Valor</Th>
        </Tr>
      </Thead>
      <Tbody>
        {rows}
      </Tbody>
      <Tfoot>
        <Tr>
          <Th></Th>
          <Th isNumeric>Q.{total.toLocaleString()}</Th>
        </Tr>
      </Tfoot>
    </Table>
  );
}