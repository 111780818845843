import axios from "axios";

export async function getBoletas(id: string) {
  const {data} = await axios.get<Boleta>(`api/boletas/${id}`);
  return data;
}

export async function aceptarBoleta(id: string) {
  const response = await axios.post<void>(`api/boletas/${id}/accept`);
  return response.status === 200;
}

export async function rechazarBoleta(id: string) {
  const response = await axios.post<void>(`api/boletas/${id}/reject`);
  return response.status === 200;
}

export interface IEmployeeInfo {
  name: string;
  empresa: string;
  bank: string;
  paymentMethod: string;
  accountType: string;
  accountNumber: string;
  fromDate: string;
  toDate: string;
}

export interface IDetailItem {
  "description": string,
  "value"?: number
  "total": number
}

export type Boleta = {
  employee: IEmployeeInfo
  ingresos: IDetailItem[]
  descuentos: IDetailItem[]
  totalIngresos: number
  totalDescuentos: number
  total: number
}